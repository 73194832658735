<div class="cont-product" style="background-color: rgb(255, 253, 253);">

  <div class="info-product" fxShow.xs="true" fxShow="false">
    <div class="subtitle divider-two">
      <h3>{{productsExpanded.name}}</h3>
      <p class="title divider-two">{{'secure.offers.list.list.EAN_offert' | translate}} {{productsExpanded.ean}}</p>
    </div>
  </div>
  <!--Imagen pequeña--> 
  <div class="smallImages vertical-scroll">
    <!-- for que contiene el arreglo de las imagenes -->
    <mat-card class="image-card" *ngFor="let img of images">
      <ng-container *ngIf="img.max" >
        <!-- cargo la url de la imagen en el src y el evento click q llama la funcion con el parametro de la img grande -->
        <div (click)="changeImage(img.max, img)" class="imageXs" [ngClass]=" (imageMax == img.max) ? 'activeImage' : ''">
          <img mat-card-image [src]="img.max" alt="X" [ngClass]=" (imageMax == img.max) ? 'border-gray' : ''">  
          <mat-divider vertical="false" [ngClass]=" (imageMax == img.max) ? 'border-blue' : ''"></mat-divider>  
        </div>
      </ng-container>
    </mat-card>
  </div>
  <mat-divider style="padding-right: 1%;" vertical="true"></mat-divider>
  <div fxLayout="row wrap" style="padding-left: 1%;" >   
    <!--Imagen grande--> 
    <div *ngIf="showImage">   
      <ng-container>         
        <img [src]="imageMax" alt="Imagen no disponible">        
      </ng-container>
    </div> 
    <!--Vídeo-->    
    <div fxFlex fxLayout="column" *ngIf="productsExpanded.hasVideoUrl && showVideo">
      <ng-container> 
        <p style="color: rgb(123, 116, 116);">Aquí: {{productsExpanded.videoUrl}}</p>
        <div>                    
          <iframe [src]="productsExpanded.videoUrl | safe: 'resourceUrl'" title="YouTube video player" 
                  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen>
          </iframe>  
        </div>
      </ng-container>
    </div> 
  </div>  
  <mat-divider style="padding-left: 1%;" vertical="true"></mat-divider>
  <div fxLayout="column" class="info-product">
    <div fxLayout="row wrap">
      <!--Título--> 
      <div fxFlex fxLayoutAlign="start" fxLayout="column">       
        <h2 style="color: rgb(99, 95, 95);">{{productsExpanded.name}}</h2>      
      </div>
      <!--Botones--> 
      <div fxLayout="column">
        <div fxFlex fxLayoutAlign="end" class="expanded-product-height-edit-icon" *ngIf="productsExpanded">
          <!--Editar--> 
          <button class="button-edit" type="button" color="primary" matTooltip="Editar"
            *ngIf="editPermission && productsExpanded.isMarketplace" matTooltipPosition="below" mat-icon-button
            mat-button (click)="editProduct(productsExpanded)">
            <mat-icon class="flexbox align-center justify-center">edit</mat-icon>
          </button>
          <!--Borrar--> 
          <button *ngIf="isAdmin && deletePermission" class="button-edit" type="button" color="primary"
            matTooltip="Eliminar" matTooltipPosition="below" mat-icon-button mat-button
            (click)="deleteProduct(productsExpanded)">
            <mat-icon class="flexbox align-center justify-center">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-divider vertical="false"></mat-divider>
    <!--EAN - PLU-->    
    <div fxLayout="row wrap">
      <div fxFlex fxLayoutAlign="start" fxLayout="column" style="padding-right: 3%;">
        <h3>{{'secure.offers.list.list.EAN_offert' | translate}} {{productsExpanded.ean}}</h3>
      </div>          
      <div fxFlex fxLayoutAlign="end" fxLayout="column">
        <h3>{{'secure.dashboard.table.top.title.SKU' | translate}}: {{productsExpanded.pluVtex}}</h3>
      </div>        
    </div>   
    <!--Reference-->
    <div fxLayout="row wrap" class="vertical-spacer" *ngIf="productsExpanded.reference !=='' && productsExpanded.reference !== null">
      <div fxFlex fxLayoutAlign="start" fxLayout="column">
        <div>
          <b>
            {{('secure.products.table_load_product.parent_reference' | translate)}}:
          </b> {{productsExpanded.reference}}
        </div>
      </div>
    </div>
    <!--Fechas-->   
    <div fxLayout="row wrap" class="vertical-spacer">
      <div fxFlex fxLayoutAlign="start" fxLayout="column">       
        <div>
          <b>
            {{('secure.products.create_product_unit.list_products.expanded_product.creation_date' | translate)}}:
          </b> {{productsExpanded.createdDate}}
        </div>         
      </div>
      <div fxFlex fxLayoutAlign="end" fxLayout="column">        
        <div>
          <b>
            {{('secure.products.create_product_unit.list_products.expanded_product.modification_date' | translate)}}:
          </b> {{productsExpanded.updatedDate}}
        </div>         
      </div>    
    </div> 
    <!--Categoría - Marca--> 
    <div fxLayout="row wrap" class="vertical-spacer" >
      <div fxFlex fxLayoutAlign="start" fxLayout="column">
        <div>
          <b>
            {{('shared.category' | translate)}}:
          </b> {{productsExpanded.category}} 
        </div>     
      </div>
      <div fxFlex fxLayoutAlign="end" fxLayout="column">        
        <div>
          <b>
            {{('shared.brand' | translate)}}:
          </b> {{productsExpanded.brand}}
        </div>        
      </div>
    </div>
    <!--Shipping size--> 
    <div fxLayout="row wrap" class="vertical-spacer" >
      <div fxFlex fxLayoutAlign="start" fxLayout="column">
        <div>
          <b>
            {{('secure.products.create_product_unit.basic_information.shipping_size' | translate)}}:
          </b> {{productsExpanded.shipping_size}}
        </div> 
      </div>
    </div>
    <!--Colores--> 
    <div fxLayout="row wrap" class="vertical-spacer" *ngIf="productsExpanded.colour.length !== 0">
      <div fxFlex fxLayoutAlign="start" fxLayout="column">
        <div>
          <i class="material-icons"> color_lens </i>
          <b>
            {{('secure.products.create_product_unit.list_products.expanded_product.color' | translate)}}:
          </b>
          <div class="color-product" [ngStyle]="{'background': '#'+productsExpanded.colour}"></div>
        </div>
      </div>
      <div fxFlex fxLayoutAlign="end" fxLayout="column">
        <div>
          <i class="material-icons"> style </i>
          <b>
            {{('secure.products.create_product_unit.list_products.expanded_product.size' | translate)}}:
          </b> {{productsExpanded.size}}
        </div>
      </div>
    </div>
    <!--Medidas - Empaque--> 
    <div fxLayout="row wrap" class="vertical-spacer" >
      <div fxFlex fxLayoutAlign="start" fxLayout="column">
        <div>
          <i class="material-icons"> shopping_basket </i>
          <b>
            {{('secure.products.create_product_unit.list_products.expanded_product.packaging_form' | translate)}}:
          </b> 
          <label>
            {{'secure.products.create_product_unit.list_products.expanded_product.height' | translate}}-{{productsExpanded.package_height}}cm
            {{'secure.products.create_product_unit.list_products.expanded_product.width' | translate}}-{{productsExpanded.package_width}}cm
            {{'secure.products.create_product_unit.list_products.expanded_product.length' | translate}}-{{productsExpanded.package_length}}cm
            {{'secure.products.create_product_unit.list_products.expanded_product.weight' | translate}}-{{productsExpanded.package_weight}}kg
          </label>
        </div>
      </div>
    </div>
    <div fxLayout="row wrap" class="vertical-spacer" >
      <div fxFlex fxLayoutAlign="start" fxLayout="column">
        <div>
          <i class="material-icons"> local_shipping </i>
          <b> 
            {{('secure.products.create_product_unit.list_products.expanded_product.products' | translate)}}:
          </b> 
          <label>
            {{'secure.products.create_product_unit.list_products.expanded_product.height' | translate}}-{{productsExpanded.item_height}}cm
            {{'secure.products.create_product_unit.list_products.expanded_product.width' | translate}}-{{productsExpanded.item_width}}cm
            {{'secure.products.create_product_unit.list_products.expanded_product.length' | translate}}-{{productsExpanded.item_length}}cm
            {{'secure.products.create_product_unit.list_products.expanded_product.weight' | translate}}-{{productsExpanded.item_weight}}kg
          </label>
        </div>
      </div>
    </div>
    <!--Mejor oferta--> 
    <div fxLayout="row wrap" class="best-offer" *ngIf="productsExpanded.bestOffer !== '0.00'">
      <div fxFlex fxLayoutAlign="start" fxLayout="column">     
        <p>{{'secure.products.create_product_unit.list_products.expanded_product.best_offer' | translate}}</p> 
      </div>
      <div fxFlex fxLayoutAlign="end" fxLayout="column">
        <p [innerHTML]="productsExpanded.bestOffer | currency: '$' : 'symbol' : '1.2-2'"></p>    
      </div>
    </div>
  </div>
</div>

<mat-expansion-panel hideToggle="true" class="ofert-expanded" *ngIf="offerPermission"
  [disabled]="productsExpanded.availableToOffer === false">
  <mat-expansion-panel-header>
    <mat-panel-title class="title-ofert" fxLayout="row" fxLayoutAlign="center center">
      <div *ngIf="productsExpanded.availableToOffer === true" fxLayout="row" fxLayoutAlign="space-around center">
        <mat-icon matPrefix>add</mat-icon>
        <h3>
          {{'secure.products.create_product_unit.list_products.expanded_product.add_offer' | translate}}
        </h3>
      </div>
      <div *ngIf="productsExpanded.availableToOffer === false" fxLayout="row" fxLayoutAlign="space-around center">
        <mat-icon matPrefix>add_alert</mat-icon>
        <h3>
          {{'secure.products.create_product_unit.list_products.expanded_product.product_not_ready_offer' | translate}}
        </h3>
        <mat-icon matPrefix>add_alert</mat-icon>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <app-ofert-expanded-product [productsExpanded]="productsExpanded" *ngIf="applyOffer" class="ofertProductExpanded"
    [applyOffer]="applyOffer"></app-ofert-expanded-product>
</mat-expansion-panel>

<div class="expanded-information-product">
  <mat-tab-group>
    <mat-tab [label]="'secure.products.create_product_unit.list_products.expanded_product.description' | translate">
      <p class="description-product" [innerHtml]="productsExpanded.description"></p>
    </mat-tab>
    <mat-tab [label]="'secure.products.create_product_unit.list_products.expanded_product.keywords' | translate"
      class="flex">
      <div class="keywords-products" *ngFor="let keywords of productsExpanded.keyword">
        <mat-chip>{{keywords}}</mat-chip>
      </div>
    </mat-tab>

    <mat-tab [label]="'secure.products.create_product_unit.list_products.expanded_product.specifications' | translate">
      <span class="no-specs" *ngIf="!productsExpanded.features  || !productsExpanded.features.length">
        {{'secure.products.create_product_unit.list_products.expanded_product.no_more_spedifications' | translate}}
      </span>
      <div class="container">
        <div class="container-sub" *ngFor="let spec of productsExpanded.features">
          <div class="container-specs_title">
            {{spec.key}}
          </div>
          <div class="container-specs_value">
            {{spec.value}}
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="expanded-information-product2">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'shared.descripcion' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <p class="description-product" [innerHtml]="productsExpanded.description"></p>
        <input matInput placeholder="Age">
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'secure.products.create_product_unit.list_products.expanded_product.keywords' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="keywords-products" *ngFor="let keywords of productsExpanded.keyword">
        <mat-chip>{{keywords}}</mat-chip>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'secure.products.create_product_unit.list_products.expanded_product.specifications' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <span *ngIf="!productsExpanded.features  || !productsExpanded.features.length">
        {{'secure.products.create_product_unit.list_products.expanded_product.no_more_spedifications' | translate}}
      </span>
      <div class="container">
        <div class="container-sub" *ngFor="let spec of productsExpanded.features">
          <div class="container-specs_title">
            {{spec.key}}
          </div>
          <div class="container-specs_value">
            {{spec.value}}
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
