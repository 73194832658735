<div class="padding-dialog">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="container-icon" fxLayout="column" fxLayoutAlign="center center">
      <mat-icon class="material-icons">
        assignment
      </mat-icon>
    </div>
  </div>

  <h1 class="center-text" mat-dialog-title>
    {{'secure.parametize.dialogAddSpecs.modal_title' | translate}}
  </h1>

  <form [formGroup]="formAddSpecs" class="form-add" (ngSubmit)="saveSpec()">
    <div mat-dialog-content class="form-container">
      <p> {{'secure.parametize.dialogAddSpecs.modal_text_1' | translate}} 
        <strong class="special" [ngClass]="{'transition': showErrorMin}">{{'secure.parametize.dialogAddSpecs.modal_text_2' | translate}}</strong>
        {{'secure.parametize.dialogAddSpecs.modal_text_3' | translate}}
      </p>
      <div fxLayout="row" class="w-100" fxLayout.xs="column" fxLayoutAlign="space-between start">
        <div class="container-form" fxLayout="column">
          <mat-form-field class="input-add">
            <input matInput formControlName="nameSpec" [placeholder]="'secure.parametize.dialogAddSpecs.modal_placeholder_specification_name' | translate">
            <mat-error *ngIf="formAddSpecs.get('nameSpec').hasError('maxlength')">{{'errors.spec_name_max_length' | translate}}</mat-error>
          <mat-error *ngIf="formAddSpecs.get('nameSpec').hasError('required')">{{'errors.required' | translate}}</mat-error>
          </mat-form-field>
          <div class="container-fields">
            <mat-form-field class="half-width">
              <input matInput id="VTEXExitoId" [placeholder]="'secure.parametize.dialog.modal_placeholder_vtex_exito_id' | translate" 
                autocomplete="false" formControlName="VTEXExitoId" required />
              <mat-error *ngIf="VTEXExitoId.hasError('required') || VTEXExitoId.hasError('trim')">{{ "errors.required" | translate }}
              </mat-error>
              <mat-error *ngIf="VTEXExitoId.hasError('pattern')">{{
                "errors.invalid_VTEXExitoId" | translate
                }}</mat-error>
            </mat-form-field>
            <mat-form-field class="half-width">
              <input matInput id="VTEXCarullaId" [placeholder]="'secure.parametize.dialog.modal_placeholder_vtex_carulla_id' | translate"
                autocomplete="false" formControlName="VTEXCarullaId" required />
              <mat-error *ngIf="
                  VTEXCarullaId.hasError('required') || VTEXCarullaId.hasError('trim')
                ">
                {{ "errors.required" | translate }}
              </mat-error>
              <mat-error *ngIf="VTEXCarullaId.hasError('pattern')">{{
                "errors.invalid_VTEXCarullaId" | translate
                }}</mat-error>
            </mat-form-field>
          </div>
          <mat-checkbox class="input-add" formControlName="requiredSpec">{{'secure.parametize.dialogAddSpecs.modal_chk_required' | translate}}</mat-checkbox>
        </div>
        <div class="container-form vis" fxLayout="column">
          <h3 class="title">{{'secure.parametize.dialogAddSpecs.modal_lbl_vendedor' | translate}}</h3>
          <mat-radio-group formControlName="optionSpec">
            <mat-radio-button value="text">{{'secure.parametize.dialogAddSpecs.modal_rdn_text' | translate}}</mat-radio-button>
            <mat-radio-button value="list">{{'secure.parametize.dialogAddSpecs.modal_rdn_list_action' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="addoption">
        <div class="widthOption" *ngFor="let opc of listOptions; let i = index" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field class="width-full">
            <input matInput [formControlName]="opc.name" [placeholder]="'secure.parametize.dialogAddSpecs.modal_placeholder_add_option' | translate">
            <mat-error>{{'errors.required' | translate}}</mat-error>
          </mat-form-field>

          <button mat-icon-button (click)="removeOpt(opc, i)" type="button">
            <mat-icon class="material-icons">
              clear
            </mat-icon>
          </button>
        </div>
      </div>

      <!-- CONTAINER BUTTON TO ADD NEW SPECIFICATION GROUP -->

    </div>

    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
      <div class="container-group-add-button" *ngIf="formAddSpecs.controls.optionSpec.value === 'list'">
        <!--BUTTON TO ADD-->
        <button type="button" mat-button (click)="addOption()">
          <mat-icon class="material-icons">
            control_point
          </mat-icon>
          {{'secure.parametize.dialogAddSpecs.modal_btn_add_option' | translate}}
        </button>
      </div>
      <button type="button" id="btn-create-transporter" mat-button color="primary" (click)="close()">{{'actions.cancel' | translate}}</button>
      <button id="btn-create-transporter" type="submit" mat-raised-button color="primary">{{'actions.accept' | translate}}</button>
    </div>
  </form>
</div>